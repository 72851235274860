import React from "react"
import { Link } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"

const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Not found" />
      <div class="m-error">
        <div class="m-error__wrapper">
          <p class="m-error__title">Erreur 404</p>
          <p class="m-error__text">Page non trouvée</p>
          
          <Link
            to="/" 
            class="m-error__link"
          >Retour vers la page d'accueil</Link>
        </div>
      </div>
  </Layout>
)

export default NotFoundPage
